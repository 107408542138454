<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap px-0 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.sections.edit_path">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                {{ item.title.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3>
                </v-flex>
            </v-layout>
            <form @submit.prevent="updateSections" autocomplete="off">
                <v-layout row wrap>

                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-text-field v-model="sections.section_name_ku" type="text"
                            :label="$store.getters.language.data.sections.section_name_ku" class="mx-1" filled outlined
                            dense required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-text-field v-model="sections.section_name_en" type="text"
                            :label="$store.getters.language.data.sections.section_name_en" class="mx-1" filled outlined
                            dense required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-text-field v-model="sections.section_name_ar" type="text"
                            :label="$store.getters.language.data.sections.section_name_ar" class="mx-1" filled outlined
                            dense required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea auto-grow rows="3" v-model="sections.section_text_ku" type="textarea"
                            :label="$store.getters.language.data.sections.section_text_ku" class="mx-1" filled outlined
                            dense>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea auto-grow rows="3" v-model="sections.section_text_en" type="textarea"
                            :label="$store.getters.language.data.sections.section_text_en" class="mx-1" filled outlined
                            dense>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea auto-grow rows="3" v-model="sections.section_text_ar" type="textarea"
                            :label="$store.getters.language.data.sections.section_text_ar" class="mx-1" filled outlined
                            dense>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea auto-grow rows="3" v-model="sections.section_description_ku" type="textarea"
                            :label="$store.getters.language.data.sections.section_description_ku" class="mx-1" filled
                            outlined dense>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea auto-grow rows="3" v-model="sections.section_description_ar" type="textarea"
                            :label="$store.getters.language.data.sections.section_description_ar" class="mx-1" filled
                            outlined dense>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea auto-grow rows="3" v-model="sections.section_description_en" type="textarea"
                            :label="$store.getters.language.data.sections.section_description_en" class="mx-1" filled
                            outlined dense>
                        </v-textarea>
                    </v-flex>
                    
                    
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="sections.section_index" type="number"
                            :label="$store.getters.language.data.sections.section_index" class="mx-1" filled outlined
                            dense required>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable :items="['home','sections','disabled']"
                            v-model="sections.section_status" dense filled outlined item-text="section_status"
                            item-value="section_status" :return-object="false"
                            :label="$store.getters.language.data.sections.section_status">
                        </v-select>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="success" type="submit">{{$store.getters.language.data.sections.update_btn}}
                        </v-btn>
                    </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <br>
                <br>
                <v-layout row wrap>
                    <!-- <v-flex xs12 lg6 xl6>

                        <v-img style="border-radius: 4px;" height="200px" :src="$imagePath + sections.section_cover" contain></v-img>
                        <h4 class="orange--text">
                            Cover Image
                        </h4>
                        <br>
                        <UploadComponent :options="{
                            table_name: 'sections',
                            column_name: 'section_cover',
                            table_key_name: 'section_id',
                            table_key_value: id,
                        }" :fileName="'no'" />
                        <br>
                    </v-flex> -->
                    <v-flex xs12 lg6 xl6>
                        <v-img style="border-radius: 4px;" height="200px" :src="$imagePath + sections.section_image" contain></v-img>
                        <h4 class="teal--text">Main Image</h4>
                        <br>
                        <UploadComponent :options="{
                            table_name: 'sections',
                            column_name: 'section_image',
                            table_key_name: 'section_id',
                            table_key_value: id,
                        }" :fileName="'no'" />
                        <br>
                    </v-flex>
                    <v-flex xs12 lg6 xl6 md3 sm4>
                        
                    </v-flex>
                    <v-flex xs12 lg6 xl6 md3 sm4>
                        
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import requests from './../../requests/sections.request.js'
    import UploadComponent from '../../components/UploadComponent.vue';
    export default {
        components: {
            UploadComponent
        },
        data() {
            return {
                sections: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },

            }
        },
        computed: {

            page() {
                const pageKey = 'SECTIONS'
                return {}
            }
        },
        mounted() {
            this.id = this.$route.params.id
            this.getOneSections()
        },
        methods: {
            getOneSections() {
                this.loading = true
                requests.getOneSections(this.id).then(r => {
                        if (r.status == 200) {
                            this.sections = r.data.sections

                            this.loading = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to read Sections',
                                color: 'error'
                            }
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Sections',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            updateSections() {
                this.loading = true
                requests.updateSections(this.id, this.sections).then(r => {
                        if (r.status == 200 && r.data.status == 'ok') {
                            this.snackbar = {
                                value: true,
                                text: 'Sections Updated',
                                color: 'success'
                            }
                            this.loading = false
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Update Faild',
                                color: 'error'
                            }
                            this.loading = false
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
        },
    }
</script>